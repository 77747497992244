import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import Loadable from 'react-loadable';

import Loader from 'components/LayoutComponents/Loader';
import IndexLayout from 'layouts';
import NotFoundPage from 'pages/404';

const loadable = loader =>
  Loadable({
    loader,
    delay: false,
    loading: () => <Loader />,
  });

const routes = [
  // Dashboard
  {
    path: '/dashboard/overview-statistics',
    component: loadable(() => import('pages/dashboard/OverviewStatisticsPage/OverviewStatistics/index')),
    exact: true,
  },
  {
    path: '/dashboard/audience-statistics',
    component: loadable(() => import('pages/dashboard/AudienceStatisticsPage/AudienceStatistics/index')),
    exact: true,
  },
  {
    path: '/dashboard/linear-channel-statistics',
    component: loadable(() => import('pages/dashboard/LinearChannelStatisticsPage/LinearChannelStatistics/index')),
    exact: true,
  },
  {
    path: '/dashboard/video-statistics',
    component: loadable(() => import('./pages/dashboard/VideoStatisticsPage/VideoStatistics/index')),
    exact: true,
  },
  {
    path: '/dashboard/download-statistics',
    component: loadable(() => import('./pages/dashboard/DownloadStatisticsPage/DownloadStatistics/index')),
    exact: true,
  },
  {
    path: '/dashboard/content-statistics',
    component: loadable(() => import('./pages/dashboard/ContentStatisticsPage/ContentStatistics/index')),
    exact: true,
  },
  {
    path: '/dashboard/health-panel',
    component: loadable(() => import('./pages/dashboard/HealthPanelPage/HealthPanel/index')),
    exact: true,
  },

  // Spotz Jobs
  {
    path: '/smart-adbreak-points',
    component: loadable(() => import('pages/smart-adbreak-points/AllSmartAdbreakPointPage/AllSmartAdbreakPoint/index')),
    exact: true,
  },
  {
    path: '/smart-adbreak-points/start-analysis-video',
    component: loadable(() => import('pages/smart-adbreak-points/EditOrAddNewSmartAdbreakPointPage/EditOrAddNewSmartAdbreakPoint/index')),
    exact: true,
  },

  // Links
  {
    path: '/links',
    component: loadable(() => import('pages/links/AllLinksPage/AllLinks/index')),
    exact: true,
  },
  {
    path: '/links/add-link',
    component: loadable(() => import('pages/links/EditOrAddNewLinksPage/EditOrAddNewLinks/index')),
    exact: true,
  },
  {
    path: '/links/edit-link/:id',
    component: loadable(() => import('pages/links/EditOrAddNewLinksPage/index')),
    exact: true,
  },

  // Notifications
  {
    path: '/notifications',
    component: loadable(() => import('pages/notifications/AllNotificationsPage/AllNotifications/index')),
    exact: true,
  },
  {
    path: '/notifications/add-notification',
    component: loadable(() => import('pages/notifications/AddNewNotificationForm/index')),
    exact: true,
  },

  // App Version
  {
    path: '/app-version',
    component: loadable(() => import('pages/app-version/AppVersionPage/AppVersion/index')),
    exact: true,
  },

  // Publica

  {
    path: '/publica',
    component: loadable(() => import('pages/publica/PublicaPage/Publica/index')),
    exact: true,
  },

  // Contents
  {
    path: '/contents',
    component: loadable(() => import('pages/contents/AllContentsPage/index')),
    exact: true,
  },
  {
    path: '/contents/add-content',
    component: loadable(() => import('pages/contents/EditOrAddNewContentsPage/index')),
    exact: true,
  },
  {
    path: '/contents/edit-content/:id',
    component: loadable(() => import('pages/contents/EditOrAddNewContentsPage/index')),
    exact: true,
  },
  {
    path: '/contents/upload-content',
    component: loadable(() => import('pages/contents/UploadContentPage/index')),
    exact: true,
  },
  {
    path: '/contents/upload-episode',
    component: loadable(() => import('pages/contents/UploadEpisodePage/index')),
    exact: true,
  },
  {
    path: '/contents/territory-tool',
    component: loadable(() => import('pages/territory-tool/AllTerritoryToolPage/AllTerritoryTool/index')),
    exact: true,
  },

  // External Channels
  {
    path: '/external-channels',
    component: loadable(() => import('pages/external-channels/AllExternalChannelPage/index')),
    exact: true,
  },
  {
    path: '/external-channels/import-external-channel',
    component: loadable(() => import('pages/external-channels/ImportExternalChannelPage/index')),
    exact: true,
  },
  {
    path: '/external-channels/:externalChannelId/edit-external-channel/:id',
    component: loadable(() => import('pages/external-channels/EditOrAddNewExternalChannelPage/index')),
    exact: true,
  },

  // Transcodes
  {
    path: '/transcodes',
    component: loadable(() => import('pages/transcodes/AllTranscodesPage/AllTranscodes/index')),
    exact: true,
  },
  {
    path: '/transcodes/add-task',
    component: loadable(() =>
      import('pages/transcodes/EditOrAddNewTasksPage/EditOrAddNewTasks/index'),
    ),
    exact: true,
  },

  // Promotions
  {
    path: '/promotions',
    component: loadable(() => import('pages/promotions/AllPromotionsPage/index')),
    exact: true,
  },
  {
    path: '/promotions/add-promotion',
    component: loadable(() => import('pages/promotions/EditOrAddNewPromotionsPage/index')),
    exact: true,
  },
  {
    path: '/promotions/edit-promotion/:id',
    component: loadable(() => import('pages/promotions/EditOrAddNewPromotionsPage/index')),
    exact: true,
  },

  // Groups
  {
    path: '/groups',
    component: loadable(() => import('pages/groups/AllGroupsPage/index')),
    exact: true,
  },
  {
    path: '/groups/add-group',
    component: loadable(() => import('pages/groups/EditOrAddNewGroupsPage/index')),
  },
  {
    path: '/groups/edit-group/:id',
    component: loadable(() => import('pages/groups/EditOrAddNewGroupsPage/index')),
    exact: true,
  },

  // Channels
  {
    path: '/channels',
    component: loadable(() => import('pages/groups/AllGroupsPage/index')),
    exact: true,
  },
  {
    path: '/channels/add-channel',
    component: loadable(() => import('pages/groups/EditOrAddNewGroupsPage/index')),
  },
  {
    path: '/channels/edit-channel/:id',
    component: loadable(() => import('pages/groups/EditOrAddNewGroupsPage/index')),
    exact: true,
  },

  // Tags
  {
    path: '/tags',
    component: loadable(() => import('pages/tags/AllTagsPage/index')),
    exact: true,
  },
  {
    path: '/tags/add-tag',
    component: loadable(() => import('pages/tags/EditOrAddNewTagsPage/index')),
  },
  {
    path: '/tags/edit-tag/:id',
    component: loadable(() => import('pages/tags/EditOrAddNewTagsPage/index')),
    exact: true,
  },

  // Feeds
  {
    path: '/mrss-feeds',
    component: loadable(() => import('pages/mrss-feeds/AllMRSSFeedsPage/index')),
    exact: true,
  },
  {
    path: '/mrss-feeds/add-mrss-feed',
    component: loadable(() => import('pages/mrss-feeds/EditOrAddNewMRSSFeedsPage/index')),
  },
  {
    path: '/mrss-feeds/edit-mrss-feed/:id',
    component: loadable(() => import('pages/mrss-feeds/EditOrAddNewMRSSFeedsPage/index')),
    exact: true,
  },

  // Genres
  {
    path: '/genres',
    component: loadable(() => import('pages/genres/AllGenresPage/index')),
    exact: true,
  },
  {
    path: '/genres/add-genre',
    component: loadable(() => import('pages/genres/EditOrAddNewGenresPage/index')),
  },
  {
    path: '/genres/edit-genre/:id',
    component: loadable(() => import('pages/genres/EditOrAddNewGenresPage/index')),
    exact: true,
  },

  // Countries
  {
    path: '/countries',
    component: loadable(() => import('pages/countries/AllCountriesPage/index')),
    exact: true,
  },
  {
    path: '/countries/add-country',
    component: loadable(() => import('pages/countries/EditOrAddNewCountriesPage/index')),
  },
  {
    path: '/countries/edit-country/:id',
    component: loadable(() => import('pages/countries/EditOrAddNewCountriesPage/index')),
    exact: true,
  },

  // Brands
  {
    path: '/brands',
    component: loadable(() => import('pages/brands/AllBrandsPage/index')),
    exact: true,
  },
  {
    path: '/brands/add-brand',
    component: loadable(() => import('pages/brands/EditOrAddNewBrandsPage/index')),
  },
  {
    path: '/brands/edit-brand/:id',
    component: loadable(() => import('pages/brands/EditOrAddNewBrandsPage/index')),
    exact: true,
  },

  // Contract Territories
  {
    path: '/contract-territories',
    component: loadable(() =>
      import('pages/contract-territories/AllContractTerritoriesPage/index'),
    ),
    exact: true,
  },
  {
    path: '/contract-territories/add-contract-territory',
    component: loadable(() =>
      import('pages/contract-territories/EditOrAddNewContractTerritoriesPage/index'),
    ),
  },
  {
    path: '/contract-territories/edit-contract-territory/:id',
    component: loadable(() =>
      import('pages/contract-territories/EditOrAddNewContractTerritoriesPage/index'),
    ),
    exact: true,
  },

  // Contract Templates
  {
    path: '/contract-templates',
    component: loadable(() => import('pages/contract-templates/AllContractTemplatesPage/index')),
    exact: true,
  },
  {
    path: '/contract-templates/add-contract-template',
    component: loadable(() =>
      import('pages/contract-templates/EditOrAddNewContractTemplatesPage/index'),
    ),
  },
  {
    path: '/contract-templates/edit-contract-template/:id',
    component: loadable(() =>
      import('pages/contract-templates/EditOrAddNewContractTemplatesPage/index'),
    ),
    exact: true,
  },

  // Similar Titles
  {
    path: '/similar-titles',
    component: loadable(() => import('pages/similar-titles/AllSimilarTitlesPage/index')),
    exact: true,
  },
  {
    path: '/similar-titles/add-similar-title',
    component: loadable(() => import('pages/similar-titles/EditOrAddNewSimilarTitlesPage/index')),
  },
  {
    path: '/similar-titles/edit-similar-title/:id',
    component: loadable(() => import('pages/similar-titles/EditOrAddNewSimilarTitlesPage/index')),
    exact: true,
  },

  // Rights
  {
    path: '/rights',
    component: loadable(() => import('pages/rights/AllRightsPage/index')),
    exact: true,
  },
  {
    path: '/rights/add-right',
    component: loadable(() => import('pages/rights/EditOrAddNewRightsPage/index')),
  },
  {
    path: '/rights/edit-right/:id',
    component: loadable(() => import('pages/rights/EditOrAddNewRightsPage/index')),
    exact: true,
  },

  // Platforms
  {
    path: '/platforms',
    component: loadable(() => import('pages/platforms/AllPlatformsPage/index')),
    exact: true,
  },
  {
    path: '/platforms/add-platform',
    component: loadable(() => import('pages/platforms/EditOrAddNewPlatformsPage/index')),
  },
  {
    path: '/platforms/edit-platform/:id',
    component: loadable(() => import('pages/platforms/EditOrAddNewPlatformsPage/index')),
    exact: true,
  },

  // Pages
  {
    path: '/pages',
    component: loadable(() => import('pages/sheets/AllPagesPage/index')),
    exact: true,
  },
  {
    path: '/pages/add-page',
    component: loadable(() => import('pages/sheets/EditOrAddNewPagesPage/index')),
  },
  {
    path: '/pages/edit-page/:id',
    component: loadable(() => import('pages/sheets/EditOrAddNewPagesPage/index')),
    exact: true,
  },

  // Users
  {
    path: '/users',
    component: loadable(() => import('pages/users/AllUsersPage/index')),
    exact: true,
  },
  {
    path: '/profile/edit-profile',
    component: loadable(() => import('pages/users/EditProfilePage/index')),
    exact: true,
  },
  {
    path: '/users/add-user',
    component: loadable(() => import('pages/users/EditOrAddNewUsersPage/index')),
  },
  {
    path: '/users/edit-user/:id',
    component: loadable(() => import('pages/users/EditOrAddNewUsersPage/index')),
    exact: true,
  },

  // Authentication
  {
    path: '/user/login',
    component: loadable(() => import('pages/user/login')),
    exact: true,
  },
  {
    path: '/user/lock',
    component: loadable(() => import('pages/user/lock')),
    exact: true,
  },
  {
    path: '/user/forgot',
    component: loadable(() => import('pages/user/forgot')),
    exact: true,
  },

  // Rights's Holders
  {
    path: '/rights-holders',
    component: loadable(() => import('pages/rights-holders/AllRightsHoldersPage/index')),
    exact: true,
  },
  {
    path: '/rights-holders/add-rights-holder',
    component: loadable(() => import('pages/rights-holders/EditOrAddNewRightsHoldersPage/index')),
  },
  {
    path: '/rights-holders/edit-rights-holder/:id',
    component: loadable(() => import('pages/rights-holders/EditOrAddNewRightsHoldersPage/index')),
    exact: true,
  },

  // Rights's Holder Groups
  {
    path: '/rights-holder-groups',
    component: loadable(() => import('pages/rights-holder-groups/AllRightsHolderGroupsPage/index')),
    exact: true,
  },
  {
    path: '/rights-holder-groups/add-rights-holder-group',
    component: loadable(() =>
      import('pages/rights-holder-groups/EditOrAddNewRightsHolderGroupsPage/index'),
    ),
  },
  {
    path: '/rights-holder-groups/edit-rights-holder-group/:id',
    component: loadable(() =>
      import('pages/rights-holder-groups/EditOrAddNewRightsHolderGroupsPage/index'),
    ),
    exact: true,
  },

  // VDMS Tags
  {
    path: '/vdms-tags',
    component: loadable(() => import('pages/vdms-tags/AllVDMSTagsPage/index')),
    exact: true,
  },
  {
    path: '/vdms-tags/add-vdms-tag',
    component: loadable(() => import('pages/vdms-tags/EditOrAddNewVDMSTagsPage/index')),
  },
  {
    path: '/vdms-tags/edit-vdms-tag/:id',
    component: loadable(() => import('pages/vdms-tags/EditOrAddNewVDMSTagsPage/index')),
    exact: true,
  },

  // Advertising TXT
  {
    path: '/txt',
    component: loadable(() => import('pages/txt/EditOrAddNewTxtPage/index')),
    exact: true,
  },

  // Vast Tags
  {
    path: '/vast-tags',
    component: loadable(() => import('pages/vast-tags/AllVastTagsPage/index')),
    exact: true,
  },
  {
    path: '/vast-tags/add-vast-tag',
    component: loadable(() => import('pages/vast-tags/EditOrAddNewVastTagsPage/index')),
  },
  {
    path: '/vast-tags/edit-vast-tag/:id',
    component: loadable(() => import('pages/vast-tags/EditOrAddNewVastTagsPage/index')),
    exact: true,
  },

  // Roles
  {
    path: '/roles',
    component: loadable(() => import('pages/roles/AllRolesPage/index')),
    exact: true,
  },
  {
    path: '/roles/add-role',
    component: loadable(() => import('pages/roles/EditOrAddNewRolesPage/index')),
  },
  {
    path: '/roles/edit-role/:id',
    component: loadable(() => import('pages/roles/EditOrAddNewRolesPage/index')),
    exact: true,
  },

  // Email
  {
    path: '/email/contact',
    component: loadable(() => import('pages/email/EmailContactPage/EmailContact/index')),
    exact: true,
  },
  {
    path: '/email/content',
    component: loadable(() => import('pages/email/EmailContentPage/EmailContent/index')),
    exact: true,
  },

  // Upload
  {
    path: '/reporting/auto-fill-blank-title-csv',
    component: loadable(() => import('pages/reporting/AutoFillBlankTitleCsvPage/index')),
    exact: true,
  },
  {
    path: '/reporting/manually-add',
    component: loadable(() => import('pages/reporting/ManuallyAddPage/index')),
    exact: true,
  },
  {
    path: '/reporting/upload-report',
    component: loadable(() => import('pages/reporting/UploadReportPage/index')),
    exact: true,
  },
  {
    path: '/reporting/delete-report',
    component: loadable(() => import('pages/reporting/DeleteReportPage/index')),
    exact: true,
  },
  {
    path: '/reporting/tool',
    component: loadable(() => import('pages/reporting/ToolPage/index')),
    exact: true,
  },

  // Tools
  {
    path: '/tools/vdms-videos',
    component: loadable(() => import('pages/tools/AllVDMSVideosPage/index')),
    exact: true,
  },

  // Log
  {
    path: '/log',
    component: loadable(() => import('pages/log/LogPage/index')),
    exact: true,
  },

  // Form Data
  {
    path: '/form-data/general-form',
    component: loadable(() => import('pages/form-data/FormDataPage/index')),
    exact: true,
  },
  {
    path: '/form-data/technical-form',
    component: loadable(() => import('pages/form-data/TechnicalFormPage/index')),
    exact: true,
  },

  // History
  {
    path: '/history',
    component: loadable(() => import('pages/history/HistoryPage/index')),
    exact: true,
  },

  // Settings
  {
    path: '/settings/setting',
    component: loadable(() =>
      import('pages/settings/EditOrAddNewSettingsPage/EditOrAddNewSettings/index'),
    ),
    exact: true,
  },

  // Advertising
  {
    path: '/settings/advertising',
    component: loadable(() =>
      import('pages/settings/EditOrAddNewAdvertisingPage/EditOrAddNewAdvertising/index'),
    ),
    exact: true,
  },

  // Advertising
  {
    path: '/settings/vdms',
    component: loadable(() => import('pages/settings/EditOrAddNewVDMSPage/EditOrAddNewVDMS/index')),
    exact: true,
  },
];

class Router extends React.Component {
  render() {
    const { history } = this.props;
    return (
      <ConnectedRouter history={history}>
        <IndexLayout>
          <Switch>
            <Route exact path="/" render={() => <Redirect to="/dashboard/overview-statistics" />} />
            {routes.map(route => (
              <Route
                path={route.path}
                component={route.component}
                key={route.path}
                exact={route.exact}
              />
            ))}
            <Route component={NotFoundPage} />
          </Switch>
        </IndexLayout>
      </ConnectedRouter>
    );
  }
}

export default Router;
